import {Injectable} from '@angular/core';
import {Contacts} from "./collections/contacts.collection";


@Injectable()
export class ContactsService {
    public lookup = new Contacts();

    constructor() {
        this.lookup.fetch();
    }
}
