import {AccessToken} from "./models/access-token.model";
import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
    public accessToken = new AccessToken();
    // TODO this should be something that is able to be changed by the user - placed in a config file somewhere.
    public PWD_DAYS_VALID: number = 90;

    constructor() {
        const accessToken = JSON.parse(localStorage.getItem('accessToken'));
        if (accessToken) {
            this.accessToken.set(accessToken);
        }
    }
}

export const globals = new Globals();
