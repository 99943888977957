import {ApiCollection} from "./api.collection";
import {Researcher} from "../models/researcher.model";
import {ApiModel} from "../models/api.model";

export const Researchers = ApiCollection.extend({
    model: Researcher,
    comparator: 'name',
    url: 'api/researchers',

});
