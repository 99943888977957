import {ApiModel} from "./api.model";
import * as _ from 'lodash';
// import {ApiCollection} from "app/services/collections/api.collection";

export const Researcher = ApiModel.extend({
    urlRoot: 'api/researchers',
    props: {
        id: 'number',
        username: 'string',
        email: 'string',
        name: 'string',
        notif_email: 'string',
        pw_change_date: 'date',
        realm: 'string',
        emailVerified: 'boolean',
        api_user_name: 'string',
        api_pword: 'string',
        api_id: 'number',
        api_uri: 'string'
    },
    derived: {
        roles: {
            deps: [],
            fn: function() {
                return ['researcher'];
            }
        }
    },
    extraProperties: 'reject',
    isLoaded$: false,

    hasRole: function(role) {
        return _.includes(this.roles, role);
    },

    cloneDeep: function () {
        const res = ApiModel.prototype.serialize.apply(this, arguments);
        // res.result_data = ResultModel.prototype.cloneDeep.apply(this.result_data, arguments);
        return res;
    },

    // Returns the count of orders for the client code and status.
    getOldestRequest: function (status) {
        return new Promise<boolean>((resolve, reject) => {

            // Call Api because we don't have the value cached
            const options = {
                url: this.url() + '/oldestRequest?status=' + status,
                timeout: 0,
                success: function (data) {
                    resolve(data);
                },
                error: function (req, res, err) {
                    reject(err);
                }
            };

            this.sync('read', new ApiModel(), options);
        })
    }
});
