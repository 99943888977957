import {Injectable} from '@angular/core';
import {SentencingActions} from "./collections/sentencing-actions.collection";


@Injectable()
export class SentencingActionsService {
    public lookup = new SentencingActions();

    constructor() {
        this.lookup.fetch();
    }

}
