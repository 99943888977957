import * as _ from 'lodash';
import * as moment from 'moment';

export interface Filter {
    where?: any,
    limit?: number,
    skip?: number,
    fields?: any,
    order?: string,
    include?: any
}

export class Query implements Filter {
    public where: any = {};

    constructor(filter?: Filter) {
        if (filter) {
            _.extend(this, filter);
        }
    }

    // Recursively filter out the empty property filters
    public formatQueryFilter(node?: Filter) {
        if (!node) {
            node = this;
        }

        // node = _.isObject(node) ? _.cloneDeep(node) : {};
        function customizer(value) {
            if (_.isDate(value)) {
                return moment(value).format();
            }
        }
        node = _.isObject(node) ? _.cloneDeepWith(node, customizer) : {};

        const recursive = (node) => {
            // First we traverse down array/object branches
            _.forOwn(node, (value, key, object) => {
                if (_.isArray(value)) {
                    _.forEach(value, (node, index, collection) => {
                        if (_.isPlainObject(node)) {
                            collection[index] = recursive(node);
                        }
                    });
                } else if (_.isPlainObject(value)) {
                    object[key] = recursive(value);
                }
            });

            //Remove all empty properties in this node
            return _.omitBy(node, (value, key) => {
                if (_.isBoolean(value)) return false;
                if (_.isNumber(value)) return false;
                return _.isEmpty(value);
            });
        };
        return recursive(node);
    }
}
