import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/single';
import 'rxjs/add/operator/toPromise';


const httpOptions = {
    // headers: new HttpHeaders({'Content-Type': 'text/plain'})
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class LoadTableService {

    constructor(private authService: AuthService, private http: HttpClient) {
    }

    public loadTable(clientCode: string, tableName: string): Promise<object> {
        if (this.authService.user.hasRole('employee')) {
            var body = {
                'clientCode': clientCode,
                'tableName': tableName
            };
            var retVal = this.http.post('/api/service/loadTable/', body, httpOptions).single().toPromise();
        }
        // console.log('Returning ' + tableName);
        return retVal;
    }

    public staccAuthenticate(clientCode: string): Promise<object> {
        if (this.authService.user.hasRole('employee')) {
            var body = {
                'clientCode': clientCode
            };
            var retVal = this.http.post('/api/service/staccAuthenticate/', body, httpOptions).toPromise();
            // var retVal = this.http.post('/api/service/staccAuthenticate/', body, httpOptions).single().toPromise();
        }
        return retVal;
    }

}
