import {ApiModel} from "./api.model";
import * as _ from 'lodash';

export const Employee = ApiModel.extend({
    urlRoot: 'api/employees',
    props: {
        id: 'number',
        username: 'string',
        email: 'string',
        name: 'string',
        phone: 'string',
        department: 'object',
        supervisor: 'boolean',
        admin: 'boolean',
        pw_change_date: 'date',
        realm: 'string',
        emailVerified: 'boolean',
    },
    derived: {
        roles: {
            deps: ['supervisor', 'admin'],
            fn: function() {
                const roles = ['employee'];
                if (this.supervisor) {
                    roles.push('supervisor');
                }
                if (this.admin) {
                    roles.push('admin');
                }
                return roles;
            }
        }
    },
    extraProperties: 'reject',
    isLoaded$: false,

    hasRole: function(role) {
        return _.includes(this.roles, role);
    }
});
