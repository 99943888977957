import {Injectable} from '@angular/core';
import {Statuses} from "./collections/statuses.collection";


@Injectable()
export class StatusesService {
    public lookup = new Statuses();

    constructor() {
        this.lookup.fetch();
    }
}
