import * as _ from 'lodash';
import {ApiModel} from "./api.model";


export const StandardDelay = ApiModel.extend({
    props: {
        id: 'number',
        client_code: 'string',
        state: 'string',
        county: 'string',
        standard_delay: 'number'
    },
    extraProperties: 'reject'
});
