import {Injectable} from '@angular/core';
import {Actions} from "./collections/actions.collection";


@Injectable()
export class ActionsService {
    public lookup = new Actions();

    constructor() {
        this.lookup.fetch();
    }

}
