import {ApiModel} from "./api.model";

export const ClientCode = ApiModel.extend({
    urlRoot: 'api/client_codes',
    props: {
        id: 'number',
        name: 'string',
        dept_id: 'number',
        api_id: 'number',
        api_user_name: 'string',
        api_pword: 'string',
        api_uri: 'string'
    },
    extraProperties: 'reject',

    // Returns the count of orders for the client code and status.
    getOldestOrder: function (status, result) {
        return new Promise<boolean>((resolve, reject) => {

            // Call Api because we don't have the value cached
            const options = {
                url: this.url() + '/oldestOrders?client=' + this.name + '&status=' + status + '&result=' + result,
                timeout: 0,
                success: function (data) {
                    resolve(data);
                },
                error: function (req, res, err) {
                    reject(err);
                }
            };

            this.sync('read', new ApiModel(), options);
        })
    },

    // Returns the count of orders for the client code and status.
    getRequestCount: function (status, result) {
        return new Promise<boolean>((resolve, reject) => {

            // Call Api because we don't have the value cached
            const options = {
                url: this.url() + '/requestsCount?client=' + this.name + '&status=' + status + '&result=' + result,
                timeout: 0,
                success: function (data) {
                    resolve(data);
                },
                error: function (req, res, err) {
                    reject(err);
                }
            };

            this.sync('read', new ApiModel(), options);
        })
    }
});
