import * as Model from 'ampersand-model';


export const AccessToken = Model.extend({
    idAttribute: 'userId',
    props: {
        id: 'string',
        userModel: 'string',
        userId: 'number',
        ttl: 'number',
        created: 'date',
    },
    urlRoot: function () {
        return 'api/' + this.userModel;
    },
    login: function (email: string, password: string) {
        let options = {data: {email: email, password: password}, method: 'post'};
        this.fetch(options);
    },
    extraProperties: 'reject',
});
