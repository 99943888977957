import {Injectable} from '@angular/core'
import {StandardDelays} from './collections/standard-delays.collection';
import * as moment from 'moment';

/**
 * Service to calculate an updated ETA
 */
@Injectable()
export class UpdatedEtaService {

    public stardardDelays = new StandardDelays();

    public async getUpdatedETA(client_code: string, state: string, county: string) {

        let stdDelay = 0;
        let newETA;

        this.stardardDelays.queryFilter.where = {
            client_code: client_code,
            state: state,
            county: county,
        };

        await this.stardardDelays.fetchPromise();

        if (!this.stardardDelays.length) {
            this.stardardDelays.queryFilter.where = {
                client_code: client_code,
                state: state,
                county: 'Statewide',
            };
            await this.stardardDelays.fetchPromise();
        }

        if (this.stardardDelays.length) {
            stdDelay = this.stardardDelays.models[0].standard_delay;
            let baseDate = moment().startOf("d").add(12, "h").toDate();
            let numberOfWeekends = Math.floor((moment(baseDate).day() + stdDelay - 1) / 5);   // calculate the number of weekends to skip over
            newETA = moment(baseDate).add((stdDelay + (numberOfWeekends * 2)), "d").toDate();
        }

        return newETA;
    }

}
