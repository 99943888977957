import {Injectable} from '@angular/core';
import {AdditionalFees} from "./collections/additional-fees.collection"


@Injectable()
export class AdditionalFeesService {
    public lookup = new AdditionalFees();

    constructor() {
        this.lookup.fetch();
    }

}
