import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard, HomeGuard} from './services/auth-guard.service';

// Import Containers
import {FullLayout, SimpleLayout} from './containers';

export const routes: Routes = [
    {
        path: '',
        component: SimpleLayout,
        canActivate: [HomeGuard],
        pathMatch: 'full',
    },
    {
        path: '',
        component: FullLayout,
        canActivate: [AuthGuard],
        data: {
            title: 'Home'
        },
        children: [
            {
                path: 'researcher',
                loadChildren: './views/researcher/researcher.module#ResearcherModule'
            },
            {
                path: 'orders',
                loadChildren: './views/orders/orders.module#OrdersModule'
            },
            {
                path: 'requests',
                loadChildren: './views/requests/requests.module#RequestsModule'
            },
            {
                path: 'error-reports',
                loadChildren: './views/reports/error-reporting.module#ErrorReportingModule'
            },
            {
                path: 'days-to-complete',
                loadChildren: './views/admin/days-to-complete/days-to-complete.module#DaysToCompleteModule'
            },
            {
                path: 'department-maint',
                loadChildren: './views/admin/department-maint/department-maint.module#DepartmentMaintModule'
            },
            {
                path: 'researcher-maint',
                loadChildren: './views/admin/researcher-maint/researcher-maint.module#ResearcherMaintModule'
            },
            {
                path: 'client-maint',
                loadChildren: './views/admin/client-maint/client-maint.module#ClientMaintModule'
            },
            {
                path: 'sentencing-actions-maint',
                loadChildren: './views/admin/sentencing-actions-maint/sentencing-actions-maint.module#SentencingActionsMaintModule'
            },
            {
                path: 'search-process-maint',
                loadChildren: './views/admin/search-process-maint/search-process-maint.module#SearchProcessMaintModule'
            },
            {
                path: 'assign-researchers',
                loadChildren: './views/admin/assign-researchers/assign-researchers.module#AssignResearchersModule'
            },
            {
                path: 'action-maint',
                loadChildren: './views/admin/action-maint/action-maint.module#ActionMaintModule'
            },
            {
                path: 'dashboard',
                loadChildren: './views/admin/dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'researcher-dashboard',
                loadChildren: './views/admin/researcher-dashboard/researcher-dashboard.module#ResearcherDashboardModule'
            },
            {
                path: 'change-log',
                // canActivateChild: [AuthGuard],
                loadChildren: './views/change-log/change-log.module#ChangeLogModule'
            },
            {
                path: 'user-profile',
                loadChildren: './views/user-profile/user-profile.module#UserProfileModule'
            },
            {
                path: 'results',
                loadChildren: './views/results/results.module#ResultsModule'
            },
            {
                path: 'test',
                loadChildren: './views/test/test.module#TestModule'
            }
        ]
    },
    {
        path: 'pages',
        component: SimpleLayout,
        data: {
            title: 'Pages'
        },
        children: [
            {
                path: '',
                loadChildren: './views/pages/pages.module#PagesModule',
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
