import {Injectable} from '@angular/core';

/**
 * Service to provide specialized print functionality.
 */
@Injectable()
export class PrintService {
    public contents = '';

    /**
     * Print specified html element's contents.
     * @param el String Id of the html element to use for printing contents.
     * @param aPrintTitle Override Title to use for the printed page title, if specified.
     */
    public printElement(el, aPrintTitle?: string) {
        return new Promise((resolve, reject) => {
            setTimeout(async () => { // Make sure angular is finished template rendering before using the DOM
                await this.printContents(el.outerHTML, aPrintTitle); // Print the contents of the html element.
                resolve();
            });
        });
    }

    /**
     * Print specified html contents.
     * @param htmlString String of html contents to use for printing.
     * @param title Override Title to use for the printed page title, if specified.
     */
    public printContents(htmlString: string, title?: string) {
        return new Promise((resolve, reject) => {
            this.contents = htmlString;
            // Save original/old html contents.
            let originalTitle: string = document.title; // Store the original document title.

            // Create html contents with what we want to print.
            if (title) document.title = title; // Only change document title if there's something new to put in.

            setTimeout(() => { // Make sure angular is finished template rendering before using the DOM
                window.print(); // Start the in-browser print process (usually popup print dialog).
                document.title = originalTitle; // Restore document title.
                this.contents = '';
                resolve();
            });
        });
    }
}
