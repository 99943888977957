import * as _ from 'lodash';
import {ApiModel} from "./api.model";

export const SentencingAction = ApiModel.extend({
    props: {
        id: 'number',
        sentencing_desc: 'string',
        api_id: 'number',
    },
    extraProperties: 'ignore',
});
