import {Component} from '@angular/core';
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-sidebar',
    templateUrl: './app-sidebar.component.html'
})
export class AppSidebar {
    private user;
    constructor(private authService: AuthService) {
        this.user = authService.user;
    }

    ngOnInit(): void {
    }
}
