import * as _ from 'lodash';
import * as Model from 'ampersand-model';
import {globals} from "../globals";
import {Query} from "../collections/query.class";


export const ApiModel = Model.extend({
    ajaxConfig: function () {
        return {
            headers: {
                'Authorization': globals.accessToken.id
            }
        };
    },
    initialize: function () {
        this.queryFilter = new Query({
            include: {},
            where: {},
            fields: {},
        });
    },

    fetch: function (options) {
        options = _.defaultTo(options, {});
        let success = _.defaultTo(options.success, _.noop);
        let error = _.defaultTo(options.error, _.noop);
        let newOptions = _.defaults({
            success: (model, response, options) => {
                success(model, response, options);
            },
            error: (model, response, options) => {
                if (response.body) {
                    const body = JSON.parse(response.body);
                    if (body.status === 401 || body.error && body.error.statusCode === 401) {
                        window.location.href = '/pages/login';
                    }
                }
                error(model, response, options);
            }
        }, options);

        return Model.prototype.fetch.call(this, newOptions);
    },

    fetchPromise: function (options) {
        options = _.defaultTo(options, {});
        let success = _.defaultTo(options.success, _.noop);
        let error = _.defaultTo(options.error, _.noop);
        return new Promise((resolve, reject) => {
            let newOptions = _.defaults({
                success: (model, response, options) => {
                    resolve(true);
                    success(model, response, options);
                },
                error: (model, response, options) => {
                    resolve(false);
                    error(model, response, options);
                }
            }, options);

            // If GET params are being overridden then don't overwrite them
            if (!options.data) {
                newOptions.data = {filter: JSON.stringify(this.queryFilter.formatQueryFilter())};
            }
            this.fetch(newOptions);
        });
    }
});
