import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/single';
import 'rxjs/add/operator/toPromise';


const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class SendRequest {

    constructor(private authService: AuthService, private http: HttpClient) {
    }


    public postSendRequest(requestXML: string, clientCode: string): Promise<object> {
        if (this.authService.user.hasRole('researcher')) {
            var body = {
                'request_XML': requestXML,
                'clientCode': clientCode
            };

            var retVal = this.http.post('/api/service/sendRequest/', body, httpOptions).single().toPromise()
        }
        return retVal;
    }

}
