import {Component} from '@angular/core';
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html'
})
export class AppHeader {

    public disabled = false;
    public status: { isopen: boolean } = {isopen: false};
    public user;

    constructor(private authService: AuthService) {
        this.user = authService.user;
    }

    public toggled(open: boolean): void {
        console.log('Dropdown is now: ', open);
    }

    public toggleDropdown($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.status.isopen = !this.status.isopen;
    }

    public logout(): void {
        this.authService.logout();
    }

    ngOnInit(): void {
    }
}
