import * as _ from 'lodash';
import {ApiModel} from "./api.model";


export const Department = ApiModel.extend({
    props: {
        id: 'number',
        name: 'string',
    },
    extraProperties: 'reject'
});
