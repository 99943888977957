import {Injectable} from '@angular/core';
import {Employees} from "./collections/employees.collection";
import {AuthService} from "./auth.service";


@Injectable()
export class EmployeesService {
    public lookup = new Employees();

    constructor(private authService: AuthService) {
        if (authService.user.hasRole('employee')) {
            this.lookup.fetch();
        }
    }
}
