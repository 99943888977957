import {Injectable} from '@angular/core'

/**
 * Service to convert state name to 2 character abbreviation
 */
@Injectable()
export class GetObjectKeyService {

    // private notDone;
    // private retIndex: number;

    public getObjectKey(objectName, targetDesc) {

        let notDone: boolean;
        let retIndex: number = null;

        if (targetDesc) {
            let objectKeys = Object.keys(objectName);
            let oIndex = -1;
            let numKeys = objectKeys.length - 1;
            notDone = true;

            while (notDone) {
                if (objectName[objectKeys[++oIndex]]["description"] == targetDesc.toUpperCase()) {
                    retIndex = Number(objectKeys[oIndex]);
                    notDone = false;
                } else if (oIndex == numKeys) {
                    retIndex = 1;
                    notDone = false;
                }
            }
        }

        return retIndex;
    }
}
