import {Injectable} from '@angular/core';
import {States} from "./collections/states.collection";

@Injectable()
export class StatesService {
    public lookup = new States();

    constructor() {
        this.lookup.fetch();
    }

    public getStateByClientAndResearcherAndStatus (clientId: number, researcherId: number, status: number[]): any[] {
        let urlSuffix: string = '?';

        if (status.length) {
            urlSuffix = status.reduce((result, val) => result + '&status=' + val, urlSuffix);
        }

        if (clientId) {
            urlSuffix = urlSuffix + '&clientId=' + clientId;
        }

        if (researcherId) {
            urlSuffix = urlSuffix + '&researcherId=' + researcherId;
        }

        if (!status.length && !researcherId && !clientId) {
            urlSuffix = '';
        }

        const StateByClientAndResearcherAndStatus = States.extend({url: 'api/states/byClientResearcherStatus' + urlSuffix});
        const states = new StateByClientAndResearcherAndStatus();
        states.fetch();

        return states.models;
    }
}
