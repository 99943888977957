import {ApiModel} from "./api.model";


export const State = ApiModel.extend({
    props: {
        id: 'number',
        name: 'string',
    },
    extraProperties: 'reject',
});
