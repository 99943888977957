import {Injectable} from '@angular/core';
import {Departments} from "./collections/departments.collection";


@Injectable()
export class DepartmentsService {
    public lookup = new Departments();

    constructor() {
        this.lookup.fetch();
    }

}
