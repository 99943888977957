import { Injectable } from '@angular/core';
import { Researchers } from './collections/researchers.collection';
import { AuthService } from './auth.service';


@Injectable()
export class ResearchersService {
    public lookup = new Researchers();

    constructor(private authService: AuthService) {
        if (authService.user.hasRole('employee')) {
            this.lookup.fetch();
        }
    }

    public getResearchersByClientAndStatus(clientId: number, status: number[]): any[] {
        let urlSuffix: string = '?';
        if (status.length) {
            urlSuffix = status.reduce((result, val) => result + '&status=' + val, urlSuffix);
        }

        if (clientId) {
            urlSuffix = urlSuffix + '&clientId=' + clientId;
        }

        if (!status.length && !clientId) {
            urlSuffix = '';
        }

        const ResearcherByClientAndStatus = Researchers.extend({url: 'api/researchers/byClientAndStatus' + urlSuffix});
        const researchers = new ResearcherByClientAndStatus();
        researchers.fetch();

        return researchers.models;
    }
    public getResearchersByActive(activeflag: boolean): any[] {
        let urlSuffix: string = '?';
        if (activeflag) {
            urlSuffix = urlSuffix + '&activeflag=' + activeflag;
        }

        if (!activeflag) {
            urlSuffix = '';
        }

        const ResearchersByActive = Researchers.extend({url: 'api/researchers/byActive' + urlSuffix});
        const researchers = new ResearchersByActive();
        researchers.fetch();

        return researchers.models;
    }
    public getResearchersAll(): any[] {
        let urlSuffix: string = '';

        const ResearchersAll = Researchers.extend({url: 'api/researchers/All' + urlSuffix});
        const researchers = new ResearchersAll();
        researchers.fetch();

        return researchers;
    }
}
