import * as _ from 'lodash';
import {ApiModel} from "./api.model";


export const Action = ApiModel.extend({
    props: {
        id: 'number',
        action_desc: 'string'
    },
    extraProperties: 'reject'
});
