import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/single';
import 'rxjs/add/operator/toPromise';


const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class MiscFunctionsService {

    constructor(private authService: AuthService, private http: HttpClient) {
    }

    public saveInternalRecords(requestID: number, reqCases: string): Promise<object> {
        if (this.authService.user.hasRole('employee')) {
            var body = {
                'requestID': requestID,
                'requestCases': reqCases,
            };
            var retVal = this.http.post('/api/service/saveInternalRecords/', body, httpOptions).single().toPromise()
        }

        return retVal;
    }
}
