import {Injectable} from '@angular/core';
import {ClientCodes} from "./collections/client-codes.collection";

@Injectable()
export class ClientCodesService {
    public lookup = new ClientCodes();


    constructor() {
        this.lookup.fetch();

    }

    public getCodesByStatusAndResearcher(status: number[], researcherId: number): any[] {
        let urlSuffix: string = '?';
        if (status.length) {
            urlSuffix = status.reduce((result, val) => result + '&status=' + val, urlSuffix);
        }

        if (researcherId) {
            urlSuffix = urlSuffix + '&researcherId=' + researcherId;
        }

        if (!status.length && !researcherId) {
            urlSuffix = '';
        }

        const CodesByStatusAndResearcher = ClientCodes.extend({url: 'api/client_codes/byStatusAndResearcher' + urlSuffix});
        const clientCodes = new CodesByStatusAndResearcher();
        clientCodes.fetch();

        return clientCodes.models;
    }

    public getCodesByStatusAndOrders(status: string): any[] {
        let urlSuffix: string = '?';
        if (status.length) {
            urlSuffix = urlSuffix + '&status=' + status;
        } else {
            urlSuffix = '';
        }

        const CodesByStatusAndOrders = ClientCodes.extend({url: 'api/client_codes/byStatusAndOrders' + urlSuffix});
        const clientCodes = new CodesByStatusAndOrders();
        clientCodes.fetch();

        return clientCodes.models;
    }

    public codesForDelay(apis: string): any[] {
        let urlSuffix: string = '?';
        if (apis.length) {
            urlSuffix = urlSuffix + '&api=' + apis;
        } else {
            urlSuffix = '';
        }

        const CodesByAPI = ClientCodes.extend({url: 'api/client_codes/codesForDelay' + urlSuffix});
        const clientCodes = new CodesByAPI();
        clientCodes.fetch();

        return clientCodes.models;
    }

    public getCodesByAPI(apis: string): any[] {
        let urlSuffix: string = '?';
        if (apis.length) {
            urlSuffix = urlSuffix + '&api=' + apis;
        } else {
            urlSuffix = '';
        }

        const CodesByAPI = ClientCodes.extend({url: 'api/client_codes/byAPICodes' + urlSuffix});
        const clientCodes = new CodesByAPI();
        clientCodes.fetch();

        return clientCodes.models;
    }
}
