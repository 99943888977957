import * as _ from 'lodash';
import {ApiModel} from "./api.model";

export const CustomAPI = ApiModel.extend({
    props: {
        api_id: 'number',
        api_desc: 'string',
    },
    extraProperties: 'ignore',
});
