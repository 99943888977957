import {ApiModel} from "./api.model";


export const StateCounty = ApiModel.extend({
    props: {
        id: 'number',
        name: 'string',
        state: 'string',
        search_process: 'string',
    },
    extraProperties: 'reject',
});
