import {ApiModel} from "./api.model";


export const Contact = ApiModel.extend({
    props: {
        id: "number",
        dept_id: "number",
        court_telephone: "string",
        court_access_fees: "string",
        researcher: "string",
        primary_backup: "string",
        updated: "string",
        county: "string",
        state: "string",
        method: "string",
        contact: "string",
        date_added: "string",
        company: "string",
        phone: "string",
        fax_number: "string",
        web_email: "string",
        web_email2: "string",
        web_email3: "string",
        price: "string",
        notes: "string",
        pub_term1: "string",
        pub_term2: "string",
        fax_address1: "string",
        fax_address2: "string",
        fax_city: "string",
        fax_state: "string",
        fax_zip: "string",
        fax_number2: "string",
        send_notif: "string"
    },
    extraProperties: 'reject',
});
