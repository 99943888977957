import {Injectable} from '@angular/core';
import {CustomAPIs} from "./collections/custom-apis.collection"


@Injectable()
export class CustomAPIsService {
    public lookup = new CustomAPIs();

    constructor() {
        this.lookup.fetch();
    }

}
